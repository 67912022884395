import Box from '@paperstac/design/lib/atoms/Box';
import React from 'react';

const Footer = React.memo(() => (
  <>
    <Box
      layoutContainer
      sx={{ bg: 'surfaceInverted', py: 2, pt: 4, borderRadius: 0, color: 'textInverted', textAlign: 'center' }}
    >
      Copyright &copy; {new Date().getFullYear()} Paperstac Inc. All rights reserved.
    </Box>
    <Box
      layoutContainer
      sx={{ bg: 'surfaceInverted', pb: 4, borderRadius: 0, color: 'textInverted', textAlign: 'center' }}
    >
      407-205-7935
    </Box>
  </>
));

Footer.displayName = 'Footer';

export default Footer;
